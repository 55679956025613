import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Login from "./features/login/Login";

import { AppRoutesEnum } from "./common/ts/enums";
import App from "./App";
import ForgotPassword from "./features/forgotPassword/ForgotPassword";
import ResetPassword from "./features/resetPassword/ResetPassword";
import Users from "./features/user/Users";
import Orders from "./features/order/Orders";
import Subscription from "./features/subscription/Subscription";
import Notification from "./features/notification/Notification";

function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={AppRoutesEnum.Login} />
        <Route element={<ForgotPassword />} path={AppRoutesEnum.ForgotPassword} />
        <Route element={<ResetPassword />} path={AppRoutesEnum.ResetPassword} />
        <Route element={<App />} path="/">
          <Route element={<Users />} path={AppRoutesEnum.Users} />
          <Route element={<Users />} path={`${AppRoutesEnum.Users}/:page`} />
          <Route element={<Orders />} path={AppRoutesEnum.Orders} />
          <Route element={<Orders />} path={`${AppRoutesEnum.Orders}/:page`} />
          <Route element={<Subscription />} path={AppRoutesEnum.Subscriptions} />
          <Route element={<Subscription />} path={`${AppRoutesEnum.Subscriptions}/:page`} />
          <Route element={<Notification />} path={AppRoutesEnum.Notifications} />
          <Route element={<Notification />} path={`${AppRoutesEnum.Notifications}/:page`} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Root;
