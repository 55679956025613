import React, { useState } from "react";

import TableRow from "src/components/ui/TableRow";
import { ISubscription } from "src/common/ts/subscription";

import EditIcon from "src/assets/images/edit.svg";
import RemoveIcon from "src/assets/images/delete.svg";
import ConfirmIcon from "src/assets/images/tick.svg";
import DenyIcon from "src/assets/images/deny.svg";

import { useSubscription } from "src/features/subscription/subscription.hooks";

type Props = {
  data: ISubscription;
  index: number;
  onEditSubscription: (data: ISubscription) => void;
  selectedSubscriptionId: string;
};

const SubscriptionsTableRow: React.FC<Props> = ({ data, index, onEditSubscription, selectedSubscriptionId }) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { deleteSubscription } = useSubscription();
  const { _id, name, description, price, duration, isDemo } = data;
  const rowCustomStyles = selectedSubscriptionId === _id ? { backgroundColor: "#f0f0f0" } : {};

  return (
    <TableRow customStyles={rowCustomStyles}>
      <th scope="row">{index + 1}</th>
      <td>
        <div className="d-flex flex-column">
          <div>{name}</div>
          <div className="text-small text-muted">{description}</div>
        </div>
      </td>
      <td>
        <span className="text-primary text-medium">{duration} dni</span>
      </td>
      <td>
        <span className="badge badge-xl bg-light mx-1">{price} zł</span>
      </td>
      <td>
        <span className="text-primary text-medium">{isDemo ? "Tak" : "Nie"}</span>
      </td>
      <td>
        <button className="btn btn-sm btn-light2 px-2 py-0" onClick={() => onEditSubscription(data)} type="button">
          <img alt="edit" src={EditIcon} />
        </button>
        <div className="delete-confirm small">
          <button
            className={`btn delete btn-sm btn-light2 px-2 py-0 ${showConfirm && "confirm"}`}
            onClick={() => setShowConfirm(true)}
            type="button"
          >
            <img alt="remove" src={RemoveIcon} />
          </button>
          <button className="yes" onClick={() => deleteSubscription(_id)} type="button">
            <img alt="confirm" src={ConfirmIcon} />
          </button>
          <button className="no" onClick={() => setShowConfirm(false)} type="button">
            <img alt="cancel" src={DenyIcon} />
          </button>
        </div>
      </td>
    </TableRow>
  );
};

export default SubscriptionsTableRow;
