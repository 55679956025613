import { useEffect } from "react";
import { toast } from "react-toastify";

import { IForgotPasswordRequest } from "src/common/ts/forgotPassword.interface";

import { useForgotPasswordMutation } from "./forgotPasswordApi";

export const useForgotPassword = () => {
  const [forgotPassword, { data, error, isLoading }] = useForgotPasswordMutation();

  useEffect(() => {
    if (data) {
      toast.success("Wysłano email z linkiem resetującym");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error("Nie znaleziono użytkownika");
    }
  }, [error]);

  return {
    error,
    handleForgotPassword: async (forgotPasswordRequest: IForgotPasswordRequest) => {
      await forgotPassword({ email: forgotPasswordRequest.email, isAdmin: true });
    },
    isLoading,
  };
};
