import React from "react";
import { Link } from "react-router-dom";

import { AppRoutesEnum } from "src/common/ts/enums";

import LogoOutline from "src/assets/images/logo-outline.svg";
import { logout } from "src/utils/logout";

const HamburgerMenu: React.FC = () => {
  return (
    <div id="menuToggle">
      <input id="mntgl" type="checkbox" />
      <span />
      <span />
      <span />
      <ul id="menu">
        <li>
          <Link to={AppRoutesEnum.Users}>Użytkownicy</Link>
        </li>
        <li>
          <Link to={AppRoutesEnum.Orders}>Zamówienia</Link>
        </li>
        <li>
          <Link to={AppRoutesEnum.Subscriptions}>Abonamenty</Link>
        </li>
        <li>
          <Link to={AppRoutesEnum.Notifications}>Powiadomienia</Link>
        </li>
        <li>
          <a href="#" onClick={logout}>
            Wyloguj
          </a>
        </li>
        <div className="row g-0">
          <div className="copyright mt-5 d-flex flex-column justify-content-start">
            <img alt="vinci logo" src={LogoOutline} />
          </div>
        </div>
      </ul>
    </div>
  );
};

export default HamburgerMenu;
