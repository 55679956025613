import { useEffect } from "react";
import { toast } from "react-toastify";

import { IListParams } from "src/app/apiConfig";

import { useLazyGetUsersQuery } from "./userApi";

export const useUser = () => {
  const [getUsers, { data: users, isFetching, error }] = useLazyGetUsersQuery();

  useEffect(() => {
    if (error) {
      toast.error("Wystąpił błąd");
    }
  }, [error]);

  return {
    handleGetUsers: async (listParams: IListParams) => {
      await getUsers(listParams);
    },
    isLoading: isFetching,
    users,
  };
};
