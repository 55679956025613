import React from "react";

type Props = {
  children: React.ReactNode;
  customStyles?: Record<string, any>;
};

const TableRow: React.FC<Props> = ({ children, customStyles }) => {
  return <tr style={customStyles}>{children}</tr>;
};

export default TableRow;
