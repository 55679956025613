import React from "react";

import LogoIcon from "src/assets/images/logo.svg";

import Footer from "./Footer";

type Props = {
  children: React.ReactNode;
  subtitle: string;
  title: string;
};

const AuthWrapper: React.FC<Props> = ({ children, title, subtitle }) => {
  return (
    <div className="container d-flex flex-column min-vh-100 justify-content-center p-0 col-12 col-md-8 position-relative">
      <div className="row g-0">
        <div className="col-12 col-md-6 px-0 rounded-xl-bottom-start hideonmobile">
          <div className="rounded-xl-start min-h-100 w-100 h-100 d-flex align-items-center" id="login-background">
            <div className="w-100 d-flex align-items-end h-100 pb-5 ps-3">
              <h1 className="display-3 ms-5 mt-3 mt-md-0 text-white font-weight-bold z-index-1000">
                Innowacyjna <br />
                analiza SEO
              </h1>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 h-100 px-md-4 px-0 pt-0">
          <div className="w-100 min-h-100 bg-foreground d-flex flex-column justify-content-center align-items-center shadow-deep pt-5 rounded-xl-end">
            <div className="mb-5 px-5">
              <div className="mb-5 mt-5 text-center">
                <img alt="vinci logo" className="login-logo mt-5" src={LogoIcon} />
                <h2 className="cta-1 mt-5 mb-0 text-primary">{title}</h2>
                <p className="text-dark text-center mb-5">{subtitle}</p>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AuthWrapper;
