import React from "react";
import { Link, useLocation } from "react-router-dom";

import LogoTopIcon from "src/assets/images/logo_top.svg";
import LogoutIcon from "src/assets/images/logout.svg";
import { AppRoutesEnum } from "src/common/ts/enums";

import { logout } from "src/utils/logout";

type HeaderLinkProps = {
  title: string;
  route: string;
};

const HeaderLink = ({ title, route }: HeaderLinkProps) => {
  const location = useLocation();

  const isSelectedRoute = () => {
    return location.pathname.includes(route);
  };

  return (
    <li>
      <Link className={`text-white nav-link ${isSelectedRoute() && "active"}`} to={route}>
        {title}
      </Link>
    </li>
  );
};

const AppHeader: React.FC = () => {
  return (
    <div className="row g-0">
      <header>
        <nav className="d-flex justify-content-between align-items-end py-4">
          <Link className="vinci-logo" to={AppRoutesEnum.Users}>
            <img alt="vinci - seo system" src={LogoTopIcon} />
          </Link>
          <div className="nav-right d-flex navigator-top">
            <ul className="nav-menu gap-5 p-0 m-0 d-flex me-5">
              <HeaderLink route={AppRoutesEnum.Users} title="Użytkownicy" />
              <HeaderLink route={AppRoutesEnum.Orders} title="Zamówienia" />
              <HeaderLink route={AppRoutesEnum.Subscriptions} title="Subskrypcje" />
              <HeaderLink route={AppRoutesEnum.Notifications} title="Powiadomienia" />
            </ul>
            <div className="navprofile d-flex gap-3 text-white align-items-end">
              <span className="p-1 ms-5">Witaj {localStorage.getItem("userName")}</span>
              <a onClick={logout} role="button" tabIndex={0}>
                <img alt="logout" className="pb-1" src={LogoutIcon} />
              </a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default AppHeader;
