import React, { useState } from "react";
import { INotification } from "src/common/ts/notification";
import TableRow from "src/components/ui/TableRow";
import { getDate, getTime } from "src/utils/time";

import EditIcon from "src/assets/images/edit.svg";
import RemoveIcon from "src/assets/images/delete.svg";
import ConfirmIcon from "src/assets/images/tick.svg";
import DenyIcon from "src/assets/images/deny.svg";

import { useNotification } from "src/features/notification/notification.hooks";

type Props = {
  data: INotification;
  index: number;
  onEdit: () => void;
  selectedNotificationId: string;
};

const NotificationsTableRow: React.FC<Props> = ({ data, index, onEdit, selectedNotificationId }) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { deleteNotification } = useNotification();
  const { _id, name, content, periodicity, dayInterval, startDate, active } = data;
  const rowCustomStyles = selectedNotificationId === _id ? { backgroundColor: "#f0f0f0" } : {};

  const renderInterval = () => {
    if (periodicity) {
      return (
        <>
          Co <span className="badge bg-outline-tertiary mx-1">{dayInterval} dni</span> od
          <span className="badge bg-outline-tertiary mx-1">{getDate(startDate)}</span>
        </>
      );
    }
    return (
      <>
        Dnia <span className="badge bg-outline-tertiary mx-1">{getDate(startDate)}</span>
      </>
    );
  };

  return (
    <TableRow customStyles={rowCustomStyles}>
      <th scope="row">{index + 1}</th>
      <td>
        <span className="text-medium">{name}</span>
      </td>
      <td>
        <span className="text-medium">{content}</span>
      </td>
      <td>
        <div className="col-6 col-md-2 d-flex align-items-center text-muted text-medium mb-1 mb-md-0">
          {renderInterval()}
        </div>
      </td>
      <td>
        <span className="text-medium text-primary">{getTime(startDate)}</span>
      </td>
      <td>
        <span className={`badge bg-outline-${active ? "primary" : "danger"}`}>{active ? "Aktywne" : "Nieaktywne"}</span>
      </td>
      <td>
        <button className="btn btn-sm btn-light2 px-2 py-0" onClick={onEdit} type="button">
          <img alt="edit" src={EditIcon} />
        </button>
        <div className="delete-confirm small">
          <button
            className={`btn delete btn-sm btn-light2 px-2 py-0 ${showConfirm && "confirm"}`}
            onClick={() => setShowConfirm(true)}
            type="button"
          >
            <img alt="remove" src={RemoveIcon} />
          </button>
          <button className="yes" onClick={() => deleteNotification(_id)} type="button">
            <img alt="confirm" src={ConfirmIcon} />
          </button>
          <button className="no" onClick={() => setShowConfirm(false)} type="button">
            <img alt="cancel" src={DenyIcon} />
          </button>
        </div>
      </td>
    </TableRow>
  );
};

export default NotificationsTableRow;
