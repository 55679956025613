import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";

import { useIsAuthQuery } from "src/features/login/loginApi";
import { AppRoutesEnum } from "src/common/ts/enums";

import Footer from "src/components/ui/Footer";
import AppHeader from "src/components/ui/AppHeader";
import HamburgerMenu from "src/components/ui/HamburgerMenu";

const App = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const navigate = useNavigate();

  useIsAuthQuery();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(AppRoutesEnum.Users);
    }
  }, [location, navigate]);

  return (
    <>
      <HamburgerMenu />
      <div className="container p-0 h-100 col-12 col-md-12 position-relative">
        <AppHeader />
        {outlet}
        <Footer />
      </div>
    </>
  );
};

export default App;
