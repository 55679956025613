import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import { ILoginRequest } from "src/common/ts/login.interface";
import { AppRoutesEnum } from "src/common/ts/enums";
import AuthWrapper from "src/components/ui/AuthWrapper";

import MailIcon from "src/assets/images/Mail.svg";
import LockIcon from "src/assets/images/Lock.svg";

import LoginSchema from "./login.schema";
import { useLogin } from "./login.hooks";

const Login = () => {
  const navigate = useNavigate();
  const { handleLogin, error } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitted },
  } = useForm<ILoginRequest>({
    resolver: yupResolver(LoginSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit(handleLogin)();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <AuthWrapper subtitle="Lorem ipsum dolor sit amet." title="Poznałeś już nasze nowości?">
      <div className="pt-5">
        <div className="tooltip-end-bottom text-center" id="loginForm">
          <div className="mb-3 filled form-group tooltip-end-top">
            <AppInput
              error={formErrors.login?.message || get(error, "data.errors.login")}
              icon={MailIcon}
              id="login"
              inputType={InputTypes.TEXT}
              isSubmited={isSubmitted}
              placeholder="Adres e-mail"
              register={register}
            />
          </div>
          <div className="mb-3 filled form-group tooltip-end-top">
            <AppInput
              error={formErrors.password?.message || get(error, "data.errors.password")}
              icon={LockIcon}
              id="password"
              inputType={InputTypes.PASSWORD}
              isSubmited={isSubmitted}
              placeholder="Hasło"
              register={register}
            />
            <a
              className="text-small position-absolute t-3 e-3"
              onClick={() => navigate(AppRoutesEnum.ForgotPassword)}
              role="button"
              tabIndex={0}
            >
              Przypomnij hasło
            </a>
          </div>
          <AppButton
            color={ButtonColorTypes.VINCI}
            customClass="btn-xl btn-primary mb-5"
            onClick={handleSubmit(handleLogin)}
            title="Zaloguj się"
          />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
