import { createApi } from "@reduxjs/toolkit/query/react";
import { IListParams, customBaseQuery } from "src/app/apiConfig";
import { IBaseListResponse } from "src/common/ts/base";
import { IUser } from "src/common/ts/user";

export const userApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    getUsers: build.query<IBaseListResponse<IUser>, IListParams>({
      providesTags: ["User"],
      query: (query) => ({
        params: { ...query, "sort[name]": "asc" },
        url: `user`,
      }),
    }),
  }),
  reducerPath: "userApi",
  tagTypes: ["User"],
});

export const { useLazyGetUsersQuery } = userApi;
