import React from "react";

export enum ButtonColorTypes {
  PRIMARY = "btn-primary",
  VINCI = "btn-vinci",
}

type Props = {
  color: ButtonColorTypes;
  title: string;
  customClass?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

const AppButton: React.FC<Props> = ({ color, title, customClass, onClick, disabled, isLoading }) => {
  return (
    <button className={`btn ${color} ${customClass || ""}`} disabled={disabled} onClick={onClick} type="button">
      {isLoading ? <div className="spinner-border text-light" role="status" /> : title}
    </button>
  );
};

export default AppButton;
