import React from "react";

import TableRow from "src/components/ui/TableRow";
import { IOrder } from "src/common/ts/order";
import { getDate } from "src/utils/time";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import { OrderStatusEnum } from "src/common/ts/enums";

type Props = {
  data: Partial<IOrder>;
  index: number;
  selectedOrderId: string;
  onGetInvoice: (id: string, invoice: string) => void;
  onShowDetails?: (user: Partial<IOrder>) => void;
  onShowAddInvoice: (id: string, number: string) => void;
};

const OrdersTableRow: React.FC<Props> = ({
  data,
  index,
  selectedOrderId,
  onGetInvoice,
  onShowDetails,
  onShowAddInvoice,
}) => {
  const { _id, number, userName, userEmail, createdAt, subscriptionName, invoice, status } = data;
  const rowCustomStyles = selectedOrderId === _id ? { backgroundColor: "#f0f0f0" } : {};
  const getOrderStatus = () => {
    switch (status) {
      case OrderStatusEnum.COMPLETED:
        return "Opłacone";
      case OrderStatusEnum.NEW:
        return "Nowe";
      case OrderStatusEnum.PENDING:
        return "Czeka na opłacenie";
      case OrderStatusEnum.CANCELLED:
        return "Anulowane";
      default:
        return "Nowe";
    }
  };

  return (
    <TableRow customStyles={rowCustomStyles}>
      <th scope="row">{index + 1}</th>
      <td>
        <span className="badge bg-outline-primary">{number}</span>
      </td>
      <td>
        <span className="badge bg-outline-primary">{getDate(createdAt)}</span>
      </td>
      <td>
        <span>{getOrderStatus()}</span>
      </td>
      <td>
        <div>{subscriptionName}</div>
      </td>
      <td>
        <div className="d-flex flex-column">
          <div>{userName}</div>
          <a className="text-small text-muted" href={`mailto:${userEmail}`}>
            {userEmail}
          </a>
        </div>
      </td>
      <td>
        {invoice ? (
          <AppButton color={ButtonColorTypes.VINCI} onClick={() => onGetInvoice(_id, invoice)} title="Pobierz" />
        ) : (
          <AppButton color={ButtonColorTypes.VINCI} onClick={() => onShowAddInvoice(_id, number)} title="Dodaj" />
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        <AppButton color={ButtonColorTypes.VINCI} onClick={() => onShowDetails(data)} title="Szczegóły" />
      </td>
    </TableRow>
  );
};

export default OrdersTableRow;
