import { createApi } from "@reduxjs/toolkit/query/react";
import { IListParams, customBaseQuery } from "src/app/apiConfig";
import {
  IBaseCreateResponse,
  IBaseDeleteDocumentRequest,
  IBaseListResponse,
  IBaseOkResponse,
} from "src/common/ts/base";
import { ICreateNotificationRequest, INotification, IUpdateNotificationRequest } from "src/common/ts/notification";

export const notificationApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    createNotification: build.mutation<IBaseCreateResponse<INotification>, ICreateNotificationRequest>({
      invalidatesTags: ["Notifications"],
      query: (data) => ({
        body: data,
        method: "POST",
        url: `notification`,
      }),
    }),
    deleteNotification: build.mutation<IBaseOkResponse, IBaseDeleteDocumentRequest>({
      invalidatesTags: ["Notifications"],
      query: ({ id }) => ({
        method: "DELETE",
        url: `notification/${id}`,
      }),
    }),
    getNotifications: build.query<IBaseListResponse<INotification>, IListParams>({
      providesTags: ["Notifications"],
      query: (query) => ({
        params: { ...query, "sort[name]": "asc" },
        url: `notification`,
      }),
    }),
    updateNotification: build.mutation<IBaseCreateResponse<INotification>, IUpdateNotificationRequest>({
      invalidatesTags: ["Notifications"],
      query: ({ id, ...data }) => ({
        body: data,
        method: "PUT",
        url: `notification/${id}`,
      }),
    }),
  }),
  reducerPath: "notificationApi",
  tagTypes: ["Notifications"],
});

export const {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetNotificationsQuery,
  useUpdateNotificationMutation,
} = notificationApi;
