import React, { ChangeEvent, useState } from "react";

import BackIcon from "src/assets/images/back.svg";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import { useOrder } from "src/features/order/order.hooks";

type Props = {
  orderId: string;
  onClosePanel: () => void;
  orderNumber: string;
};

const AddInvoice: React.FC<Props> = ({ orderNumber, orderId, onClosePanel }) => {
  const [file, setFile] = useState<File>();
  const { addInvoice, addInvoiceLoading } = useOrder();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <aside className={`sidebar sidebar-right sidebar-right-order col-sm-12 col-md-3 ${orderId && "active"}`}>
      <div className="sidebar-content py-5 px-3">
        <img alt="go-back" className="backbtn" onClick={onClosePanel} role="button" src={BackIcon} />
        <h3 className="text-white text-center mb-5">Zamówienie {orderNumber}</h3>
        <div className="row g-2">
          <label className="col-12 top-label">
            <section className="scroll-section">
              <h2 className="small-title text-white ps-3 mt-3">Dodaj fakturę</h2>
              <div className="card">
                <div className="card-body p-1">
                  <div className="input-group mx-0">
                    <input className="form-control p-3" id="inputGroupFile01" onChange={handleFileChange} type="file" />
                  </div>
                </div>
              </div>
            </section>
          </label>
          <div className="col-12">
            <AppButton
              color={ButtonColorTypes.PRIMARY}
              customClass="col-12"
              disabled={!file}
              isLoading={addInvoiceLoading}
              onClick={() => addInvoice(file, orderId)}
              title="Dodaj"
            />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default AddInvoice;
