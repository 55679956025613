import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { get } from "lodash";
import { logout } from "src/utils/logout";

export const getAuthToken = (): string | null => localStorage.getItem("token");
// export const baseUrl = "https://api.vinci.uniquedevs.usermd.net";
export const baseUrl = "https://api-v2.vinci.uniquedevs.usermd.net";
// export const baseUrl = "http://localhost:3001";

export interface IListParams {
  [field: string]: string | number | boolean;
}

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (get(result, "error.status") === 401 && window.location.pathname !== "/login") {
    logout();
  }
  return result;
};

export const setFormData = (obj: Record<string, any>, form?: FormData, namespace?: string) => {
  const fd = form || new FormData();
  let formKey;
  // eslint-disable-next-line
  for (const property in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        // eslint-disable-next-line
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
        setFormData(obj[property], fd, formKey);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};
