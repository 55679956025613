import React from "react";
import { get } from "lodash";

import InfoWithLabel from "src/components/ui/InfoWithLabel";
import BackIcon from "src/assets/images/back.svg";
import { IOrder } from "src/common/ts/order";

type Props = {
  order: Partial<IOrder>;
  onClosePanel: () => void;
};

const OrderDetails: React.FC<Props> = ({ order, onClosePanel }) => {
  return (
    <aside className={`sidebar sidebar-right sidebar-right-order col-sm-12 col-md-3 ${order && "active"}`}>
      <div className="sidebar-content py-5 px-3">
        <img alt="go-back" className="backbtn" onClick={onClosePanel} role="button" src={BackIcon} />
        <h3 className="text-white text-center mb-5">{order?.number}</h3>
        <div className="row g-2">
          <label className="col-12 top-label">
            <section className="scroll-section">
              <InfoWithLabel label="Numer telefonu" value={get(order, "userName", "-")} />
              <InfoWithLabel label="Numer telefonu" value={get(order, "userPhone", "-")} />
              <InfoWithLabel label="Nazwa firmy" value={get(order, "billingDetails.companyName", "-")} />
              <InfoWithLabel label="NIP" value={get(order, "billingDetails.companyId", "-")} />
              <InfoWithLabel label="Adres" value={get(order, "billingDetails.address", "-")} />
              <InfoWithLabel label="Kod pocztowy" value={get(order, "billingDetails.postalCode", "-")} />
              <InfoWithLabel label="Miasto" value={get(order, "billingDetails.city", "-")} />
            </section>
          </label>
        </div>
      </div>
    </aside>
  );
};

export default OrderDetails;
