import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { range } from "lodash";

import { AppRoutesEnum } from "src/common/ts/enums";
import { IUserFilteringData } from "src/common/ts/user";

import PreviousIcon from "src/assets/images/arr-left.svg";
import NextIcon from "src/assets/images/arr-right.svg";

type Props = {
  total: number;
  filteringData: IUserFilteringData;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  limit?: number;
  route: AppRoutesEnum;
};

const Pagination: React.FC<Props> = ({ filteringData, total, limit = 20, onChange, route }) => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [offset, setOffset] = useState<number>(0);

  const currentPage = page ? Number(page) : 1;
  const currentOffset = currentPage * limit - limit;
  const totalPages = Math.ceil(total / limit);

  useEffect(() => {
    if (currentPage < 1 || currentPage > totalPages) return navigate(route);
  }, [currentPage, navigate, totalPages, route]);

  useEffect(() => {
    if (currentPage && currentOffset !== offset) {
      setOffset(currentOffset);

      const filterDataTmp = { ...filteringData };
      filterDataTmp.offset = currentOffset;
      filterDataTmp.limit = limit;

      onChange(filterDataTmp);
    }
  }, [filteringData, currentPage, currentOffset, offset, limit, onChange]);

  const renderPaginationPages = (pages: number[]) => {
    return (
      <>
        {pages.map((paginationPage: number, index: number) => {
          return (
            <li
              className={`page-item ${currentPage === paginationPage ? "active" : ""}`}
              key={`pagination-page-${index}`}
            >
              <Link className="page-link" to={`${route}/${paginationPage}`}>
                {paginationPage}
              </Link>
            </li>
          );
        })}
      </>
    );
  };

  const generateLeftPages = () => {
    let pages = range(1, 6);

    if (totalPages < 8) {
      pages = range(1, 8);
    }

    pages = pages.filter((pageNumber: number) => pageNumber <= totalPages);

    if (currentPage > 4 && totalPages >= 8) {
      pages.splice(1, 4);
    }

    return (
      <>
        {renderPaginationPages(pages)}
        {totalPages >= 8 && <li className="page-item mx-3">...</li>}
      </>
    );
  };

  const generateCenterPages = () => {
    if (totalPages <= 8 || currentPage < 5 || (currentPage > totalPages - 4 && currentPage > 5)) {
      return null;
    }

    const pages = [currentPage - 1, currentPage, currentPage + 1];

    return (
      <>
        {renderPaginationPages(pages)}
        <li className="page-item mx-3">...</li>
      </>
    );
  };

  const generateRightPages = () => {
    if (totalPages < 8) {
      return null;
    }

    let pages = range(totalPages - 4, totalPages + 1);
    pages = pages.filter((pageNumber: number) => pageNumber <= totalPages);

    if (currentPage < totalPages - 3) {
      pages.splice(0, 4);
    }

    return renderPaginationPages(pages);
  };

  const leftArrow = () => {
    if (!totalPages || (total && currentPage === 1)) {
      return null;
    }

    return (
      <li className="page-item">
        <Link className="page-link" to={`${route}/${currentPage - 1}`}>
          <img alt="prev arrow" src={PreviousIcon} />
        </Link>
      </li>
    );
  };

  const rightArrow = () => {
    if (!totalPages || (total && currentPage >= totalPages)) {
      return null;
    }

    return (
      <li className="page-item">
        <Link className="page-link" to={`${route}/${currentPage + 1}`}>
          <img alt="next arrow" src={NextIcon} />
        </Link>
      </li>
    );
  };

  return (
    <section className="col-12 d-flex justify-content-center">
      <nav>
        <ul className="pagination">
          {leftArrow()}
          {generateLeftPages()}
          {generateCenterPages()}
          {generateRightPages()}
          {rightArrow()}
        </ul>
      </nav>
    </section>
  );
};

export default Pagination;
