import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { IListParams } from "src/app/apiConfig";
import { AppRoutesEnum } from "src/common/ts/enums";
import { INotification } from "src/common/ts/notification";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import ModuleContentContainer from "src/components/ui/ModuleContentContainer";
import PageWrapper from "src/components/ui/PageWrapper";
import Pagination from "src/components/ui/Pagination";
import Table from "src/components/ui/Table";
import NotificationsTableRow from "src/features/notification/NotificationsTableRow";
import { useNotification } from "src/features/notification/notification.hooks";
import AddNotification from "src/features/notification/AddNotification";

const TABLE_HEADERS = ["#", "Nazwa", "Treść", "Cykliczność", "Godzina", "Status", ""];

const Notification = () => {
  const [filteringData, setFilteringData] = useState<IListParams>({});
  const [selectedNotification, setSelectedNotification] = useState<Partial<INotification>>();
  const { notifications, fetchNotifications, fetchListLoading } = useNotification();

  useEffect(() => {
    fetchNotifications(filteringData);
  }, [filteringData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedNotification(null);
  }, [notifications]);

  const renderRows = () => {
    if (!notifications) return null;
    return notifications.documents.map((d, i) => {
      return (
        <NotificationsTableRow
          data={d}
          index={i}
          key={d._id}
          onEdit={() => setSelectedNotification(d)}
          selectedNotificationId={selectedNotification?._id}
        />
      );
    });
  };

  return (
    <PageWrapper>
      <AddNotification onClose={() => setSelectedNotification(null)} selectedNotification={selectedNotification} />
      <ModuleContentContainer isSidebarActive={!!selectedNotification}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-weight-bold m-0">Powiadomienia</h3>
          <AppButton color={ButtonColorTypes.VINCI} onClick={() => setSelectedNotification({})} title="Dodaj" />
        </div>
        <hr />
        <section className="scroll-section" id="breakpointSpecificResponsive">
          <div className="card">
            <div className="card-body p-0">
              <Table headers={TABLE_HEADERS} isLoading={fetchListLoading} rows={renderRows()} />
              <Pagination
                filteringData={filteringData}
                onChange={setFilteringData}
                route={AppRoutesEnum.Notifications}
                total={get(notifications, "total", 1)}
              />
            </div>
          </div>
        </section>
      </ModuleContentContainer>
    </PageWrapper>
  );
};

export default Notification;
