import React, { useState } from "react";
import { UseFormRegister } from "react-hook-form";

export enum InputTypes {
  TEXT = "text",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
  TIME = "time",
}

type Props = {
  id: string;
  inputType: InputTypes;
  register: UseFormRegister<any>;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  isSubmited?: boolean;
  icon?: string;
  iconEnd?: string;
};

const AppInput: React.FC<Props> = ({
  id,
  inputType,
  register,
  error,
  placeholder,
  disabled,
  isSubmited,
  icon,
  iconEnd,
}) => {
  const [type, setType] = useState(inputType);
  const [hideError, setHideError] = useState(false);
  const inputClasses = `form-control ${error ? "is-invalid" : ""} ${isSubmited && !error ? "is-valid" : ""}`;

  const showValue = () => {
    if (type === InputTypes.PASSWORD) {
      setType(InputTypes.TEXT);
    } else {
      setType(InputTypes.PASSWORD);
    }
  };

  return (
    <>
      {icon && <img alt="" src={icon} />}
      <input
        {...register(id)}
        className={inputClasses}
        disabled={disabled}
        onBlur={() => setHideError(false)}
        placeholder={placeholder}
        type={type}
      />
      {iconEnd && (
        <img alt="" className="position-absolute t-2 e-3" onClick={showValue} role="presentation" src={iconEnd} />
      )}

      {error && !hideError && <div className="invalid-tooltip">{error}</div>}
    </>
  );
};

export default AppInput;
