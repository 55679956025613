import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { INotification, INotificationFormValues } from "src/common/ts/notification";

import BackIcon from "src/assets/images/back.svg";
import LabeledContainer from "src/components/ui/LabeledContainer";
import AppInput, { InputTypes } from "src/components/form/AppInput";

import AddNotificationSchema from "src/features/notification/addNotification.schema";
import AppButton, { ButtonColorTypes } from "src/components/ui/AppButton";
import AppTextarea from "src/components/form/AppTextarea";
import AppDatePicker from "src/components/ui/AppDatePicker";
import AppCheckbox from "src/components/form/AppCheckbox";
import { getTime } from "src/utils/time";
import { useNotification } from "src/features/notification/notification.hooks";

type Props = {
  selectedNotification: Partial<INotification>;
  onClose: () => void;
};

const AddNotification: React.FC<Props> = ({ onClose, selectedNotification }) => {
  const [id, setId] = useState<string>();
  const { createNotification, updateNotification } = useNotification();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors: formErrors, isSubmitted },
  } = useForm<INotificationFormValues>({
    resolver: yupResolver(AddNotificationSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (selectedNotification) {
      const { _id, name, content, startDate, periodicity, dayInterval, active, isPush, isWebPush } =
        selectedNotification;
      if (_id) setId(_id);
      setValue("name", name);
      setValue("content", content);
      setValue("startDate", startDate && new Date(startDate));
      setValue("time", startDate && getTime(startDate));
      setValue("periodicity", periodicity);
      setValue("dayInterval", dayInterval);
      setValue("active", active);
      setValue("isPush", isPush);
      setValue("isWebPush", isWebPush);
    } else {
      setId(null);
    }
  }, [selectedNotification, setValue]);

  const setNotification = (data: INotificationFormValues) => {
    const parsedData = {
      ...data,
      startDate: moment(`${moment(data.startDate).format("YYYY-MM-DD")} ${data.time}`).toDate(),
      users: selectedNotification?.users || [],
    };
    if (!id) {
      createNotification(parsedData);
    } else {
      updateNotification({ id, ...parsedData });
    }
  };

  return (
    <aside className={`sidebar sidebar-right col-sm-12 col-md-3 ${selectedNotification && "active"}`}>
      <div className="sidebar-content py-5 px-3">
        <img alt="back" className="backbtn" onClick={onClose} role="button" src={BackIcon} />
        <h3 className="text-white text-center mb-5">{id ? watch("name") : "Nowe powiadomienie"}</h3>
        <div className="row g-2">
          <LabeledContainer customClass="col-12" title="Nazwa">
            <AppInput
              error={formErrors.name?.message}
              id="name"
              inputType={InputTypes.TEXT}
              isSubmited={isSubmitted}
              register={register}
            />
          </LabeledContainer>
          <LabeledContainer customClass="col-12" title="Treść">
            <AppTextarea error={formErrors.content?.message} id="content" register={register} />
          </LabeledContainer>
          <LabeledContainer customClass="col-6" title="Start">
            <Controller control={control} name="startDate" render={({ field }) => <AppDatePicker field={field} />} />
          </LabeledContainer>
          <LabeledContainer customClass="col-6" title="Godzina">
            <AppInput
              error={formErrors.time?.message}
              id="time"
              inputType={InputTypes.TIME}
              isSubmited={isSubmitted}
              register={register}
            />
          </LabeledContainer>
          <LabeledContainer customClass="col-6 align-items-center d-flex">
            <AppCheckbox error={formErrors.isPush?.message} id="isPush" label="App Push" register={register} />
          </LabeledContainer>
          <LabeledContainer customClass="col-6 align-items-center d-flex">
            <AppCheckbox error={formErrors.isWebPush?.message} id="isWebPush" label="Web Push" register={register} />
          </LabeledContainer>
          <LabeledContainer customClass="col-6 align-items-center d-flex">
            <AppCheckbox error={formErrors.active?.message} id="active" label="Aktywny" register={register} />
          </LabeledContainer>
          <LabeledContainer customClass="col-6 align-items-center d-flex">
            <AppCheckbox
              error={formErrors.periodicity?.message}
              id="periodicity"
              label="Cykliczność"
              register={register}
            />
          </LabeledContainer>
          <LabeledContainer customClass="col-12" title="Interwał dni">
            <AppInput
              disabled={!watch("periodicity")}
              error={formErrors.dayInterval?.message}
              id="dayInterval"
              inputType={InputTypes.NUMBER}
              isSubmited={isSubmitted}
              register={register}
            />
          </LabeledContainer>
          <div className="col-12">
            <AppButton
              color={ButtonColorTypes.PRIMARY}
              customClass="col-12 mt-5"
              onClick={handleSubmit(setNotification)}
              title="Zapisz"
            />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default AddNotification;
